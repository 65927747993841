import React from 'react';
import { useToasts } from 'react-toast-notifications';

import Web3 from 'web3';
import WithToast from '../Common/WithToast.js'
import mutantmoneysociety from './../../contracts/Mutantmoneysociety.json'


const CyberpresaleLoading = (props) => {
    return (

        <div className="row justify-content-center">
            <div className="col-1 justify-content-right pr-0">
                <div className="shape-img4 pl-5">


                </div>
            </div>
            <div className="col-2 pl-2">
                <p>Loading...</p>
            </div>
        </div>
    )
}









class Cyberpresale extends React.Component {



    //network and web3 logic

    async loadWeb3(dispatch) {
      //console.log("loadWeb3")
      if (window.ethereum) {

       //console.log("Starting up....")
        const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
        const netId = await web3.eth.net.getId()     // gets the value of the network
        const enabledWeb3 = await ethereum.enable();
        const accounts = await web3.eth.getAccounts()

        if (typeof accounts[0] !== 'undefined') {

          this.setState({ account: accounts[0]})

        } else {
          window.alert('Please login with MetaMask')
        }


        //load contracts
        try {


          const contract = new web3.eth.Contract(mutantmoneysociety.abi, mutantmoneysociety.networks[netId].address)  // comes from ABI json file

           this.setState({ contract })

          //check if that account is whitelisted -- Checking directly from the blockchain (so can buy from presale)
          const whiteListed = await contract.methods.whitelist(this.state.account).call().catch(error => {

          });

          this.setState({ whiteListed })
          //console.log("from cybersale: whitelisted>? :", this.state.whiteListed )


        } catch (e) {
          console.log('Error from this loooooog', e)
          window.alert('ERRORs -- Generic error in the web3')
        }

      } else {
        window.alert('Please install MetaMask')
      }
    }   // END OF LOADING block chain data





    // Minting function
    async wemint(tknumber, totalpayamount, event) {

        if (window.ethereum) {
            const web3 = new Web3(window.ethereum)
            const accounts = await web3.eth.getAccounts()
            //load balance
            if (typeof accounts[0] === 'undefined') {
                event.target.reset();
                this.props.addToast('Sorry, please connect the metamask', { appearance: 'error' });
                return
            } else {

                this.props.updateAccount(accounts[0])

            }
        }

        //console.log("this.props.currentPrice --> ", this.props.currentPrice)
        //console.log("this.props.account --> ", this.props.account)
        //console.log("this.state.contract --> ", this.state.contract)


        if (this.props.contract !== 'undefined') {
            try {

                await this.state.contract.methods.PreSaleMintFromWhiteList(tknumber).send({ from: this.props.account, value: totalpayamount })
                    .on('transactionHash', function (hash) {
                        //console.log("transactionHash State")

                        window.location.search = '?success';
                    })
                    .once('receipt', (receipt) => {
                        this.setState({
                            action: "buy"
                        })
                        event.target.reset();
                    })


            } catch (e) {
                event.target.reset();
                this.props.addToast('Sorry, there has been an error', { appearance: 'error' });
                console.log('Error, withdraw: ', e)
            }
        }
    }



    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: true,
            currentPrice: this.props.currentPrice,
            action: "wait",
            contract: null,
            whiteListed: false,

        };

        // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
    }


    async componentDidMount() {

        const queryString = window.location.search;
        //console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const successParam = urlParams.has('success')
        if (successParam) {
            window.history.pushState(null, null, window.location.pathname);
            this.props.addToast('Thanks for your purchase - Please wait for Blockchain confirmation', { appearance: 'success' });
        }


        if (this.props.currentPrice == 100) {
            this.setState({ currentPrice: 1000 })
            this.props.addToast('Sale has already ended!', { appearance: 'success' });
        }

        await this.loadWeb3(this.props.dispatch)

    }



    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.currentPrice !== prevProps.currentPrice) {
            this.setState({ currentPrice: this.props.currentPrice })
        }



        //getting the whitelisted status of the connected account
    //    const whiteListed = await this.props.contract.methods.whitelist(this.props.account).call().catch(error => {
    //    });
    //    this.setState({ whiteListed })
    //    console.log("Cybersale and status:", this.state.vipwhiteListed )

    }

    render() {

        return (
            <React.Fragment>

                <section className="what-we-do-area bg-black pt-100 pb-100">

                    <div className="container ">



                        {this.props.currentPrice != 10 ?
                            <div className="row justify-content-center">
                                <div className="col-10">



                                    {((this.state.currentPrice == Web3.utils.toWei('1000', 'ether')) || (this.props.presaleison != true)) ?


                                        <div className="feature feature-1 service-1 text-center">
                                            <hr />
                                            <h4> PRESALE not LIVE yet </h4>
                                            <div>Please check Discord for more information.</div>
                                            <hr />
                                        </div>


                                        :
                                        this.state.whiteListed == true? 
  
                                        <div className="feature feature-1 service-1 text-center">

                                            <h4>Price Now : {this.state.currentPrice / (10 ** 18)} ETH  </h4><hr />

                                            <p align="centers"> Quantity to Buy (max {this.props.max_NFT_ATONCE_PRESALE} at once)</p>



                                            <form onSubmit={(e) => {
                                                e.preventDefault()    //doesnt let the page refresh

                                                const tknumber = this.msize.value
                                                const totalPrice = tknumber * this.state.currentPrice;
                                                console.log('totalPrice: ', totalPrice / (10 ** 18), "ETH")
                                                //    this.wemint(tknumber,totalPrice.toString())


                                                this.wemint(tknumber, totalPrice.toString(), e)


                                            }}>
                                                <div className="form-row justify-content-center">
                                                    <div className="form-group col-4 ">
                                                        <input
                                                            type='number'
                                                            className="form-control mb-3"
                                                            placeholder={'e.g. 1-'+this.props.max_NFT_ATONCE_PRESALE}
                                                            ref={(input) => { this.msize = input }}
                                                            min="1"
                                                            max={this.props.max_NFT_ATONCE_PRESALE}
                                                            required />

                                                        <input type='submit' className='btn btn-block default-btn-custom' value='PURCHASE' />
                                                    </div>
                                                </div>
                                            </form>


                                            <hr />

                                        </div>
                                        :
                                        <div className="feature feature-1 service-1 text-center">
                                            <hr />
                                            <h4> You are not Whitelisted yet </h4>
                                            <hr />
                                        </div>

                                    }




                                </div>
                            </div>
                            :

                            <CyberpresaleLoading />}




                    </div>


                </section>

            </React.Fragment>
        )
    }
}
// Specifies the default values for props:
Cyberpresale.defaultProps = {
    currentPrice: 10
};


export default WithToast(Cyberpresale);
